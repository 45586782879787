<template>
  <div>
    <div v-for="item in items" :key="item" class="media mb-4">
      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
        <i class="fas fa-check"></i>
      </span>
      <div class="media-body text-dark">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        "Over 150 tax treaties, deductions, and credits covered",
        "FICA (Medicare and Social Security) tax return – for eligible F-1, J-1 M-1, Q-1, and Q-2 visa holders",
        `Supported states: Alaska, Florida, California, Nevada, New Hampshire,
        South Dakota, Tennessee, Texas, Washington and Wyoming. Other states
        will be supported soon.`,
        "Free form 8843 when filing a federal tax return",
        "A hassle-free and user-friendly filing experience – file your taxes in as low as 15 minutes!",
        "Immediate download of your tax forms",
        "Best price for nonresident tax filing, guaranteed",
        "24/7 support – 1-on-1 support is always one click away",
      ]
    };
  }
};
</script>
