<template>
  <div>
    <div class="text-center mx-md-auto" :class="marginClasses">
      <h2 :class="headerClass">
        {{ headerText }}
      </h2>
    </div>
    <ul
      class="step step-dashed step-centered step-border-last-0"
      :class="stepBreakPointClass"
    >
      <li class="step-item p-0" :class="stepItemClass">
        <div class="step-content-wrapper ">
          <span
            class="step-icon step-icon-soft-primary"
            :class="`step-icon-${stepSize}`"
            >1</span
          >
          <div class="step-content" data-aos="fade-up" data-aos-delay="0">
            <figure class="ml-1 bs-mb-2" :class="figureClasses">
              <img
                class="img-fluid"
                src="@/assets/svg/icons/icon-67.svg"
                alt="SVG"
              />
            </figure>
            <h3 :class="subHeaderClass">Answer simple questions</h3>
            <p v-if="!withoutSubtext" class="larger_mobile">
              We'll ask you about your visa type and nationality, for example.
              Our questionnaire adjusts to your answers and ensures that you are
              only answering what's truly required.
            </p>
          </div>
        </div>
      </li>
      <li class="step-item p-0" :class="stepItemClass">
        <div class="step-content-wrapper">
          <span
            class="step-icon   step-icon-soft-primary"
            :class="`step-icon-${stepSize}`"
            >2</span
          >
          <div class="step-content" data-aos="fade-up" data-aos-delay="150">
            <figure class=" ml-1  bs-mb-2" :class="figureClasses">
              <img
                class="img-fluid"
                src="@/assets/svg/icons/icon-31.svg"
                alt="SVG"
              />
            </figure>
            <h3 :class="subHeaderClass">
              We do the math and maximize your tax refund
            </h3>
            <p v-if="!withoutSubtext" class="larger_mobile">
              Our algorithm identifies tax treaties relevant to your nationality
              and applies other deductions to maximize your tax refund.
            </p>
          </div>
        </div>
      </li>

      <li class="step-item p-0" :class="stepItemClass">
        <div class="step-content-wrapper">
          <span
            class="step-icon  step-icon-soft-primary"
            :class="`step-icon-${stepSize}`"
            >3</span
          >
          <div class="step-content" data-aos="fade-up" data-aos-delay="300">
            <figure class=" ml-1  bs-mb-2" :class="figureClasses">
              <img
                class="img-fluid"
                src="@/assets/svg/icons/icon-40.svg"
                alt="SVG"
              />
            </figure>
            <h3 :class="subHeaderClass">Print and mail your tax forms</h3>
            <p v-if="!withoutSubtext" class="larger_mobile">
              After printing your forms, all that's left to do is sign and mail
              them. We'll provide you with simple instructions on where you
              should sign and how to mail your forms.
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    withoutSubtext: {
      type: Boolean,
      default: false
    },
    stepSize: {
      type: String,
      default: "lg"
    },
    subHeaderClass: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: "8"
    },
    headerClass: {
      type: String,
      default: null
    },
    marginClasses: {
      type: String,
      default: "mb-5 mb-md-6"
    },
    alwaysVertical: {
      type: Boolean,
      default: false
    },
    stepBreakPoint: {
      type: String,
      default: "md"
    },
    stepItemClass: {
      type: String,
      default: "px-sm-3 pr-3"
    },
    headerText: {
      type: String,
      default: "File your taxes in 3 simple steps"
    }
  },
  computed: {
    stepBreakPointClass() {
      if (this.alwaysVertical) {
        return null;
      }

      return `step-${this.stepBreakPoint}`;
    },
    figureClasses() {
      var classes = [`max-w-${this.iconSize}rem`];

      if (!this.alwaysVertical) {
        classes.push(`mx-${this.stepBreakPoint}-auto`);
      }

      return classes;
    }
  }
};
</script>
