<template>
  <div>
    <div class="row">
      <div class="pricing-card-col">
        <div class="position-relative h-100">
          <div
            class="card clean_card h-100 pricing-card"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="card-header border-0 text-center">
              <span class="d-block h3 mb-0 pricing-card-header"
                >Federal – Form 1040NR</span
              >
              <span class="d-block">
                <span class="font-size-4 text-primary font-weight-bold">
                  <span class="mr-n2"
                    ><template v-if="showOnlys"> Only</template> ${{ FEDERAL_PRICE }}</span
                  >
                </span>
              </span>
              <span class="d-block">
                <span class="tooltip_">
                  Required if you had any source of

                  <tooltip>
                    U.S. income
                    <template v-slot:content>
                      Wages, scholarships, awards, capital gains, taxable
                      interest, stocks or other assets traded through a U.S.
                      broker, or any other income originating from a
                      U.S.-related source.
                    </template>
                  </tooltip>
                </span>
              </span>
            </div>
            <div class="card-body">
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>Maximize Your Federal Tax Refund</strong>: Over 150
                  tax treaty articles, deductions and credits are reviewed and
                  automatically applied based on your nationality and status.
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>File with Confidence: </strong> Your taxes will be
                  filed accurately and include all required schedules for your
                  case, to ensure compliance with the IRS requirements.
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>8843 Included: </strong> F, J, M and Q visa holders,
                  your 8843 is included with your federal tax forms. If you have
                  children on dependent visas, their 8843's are offered for free
                  as well!
                </div>
              </div>
              <!-- <div class="media font-size-1 mb-3">
											<i class="fas fa-check-circle text-success mt-1 mr-2"></i>
											<div class="media-body">
												<strong>HSA and HDHP Friendly: </strong> Thriftax is the
												only online tax filing solution that supports tax filers
												with High Deductible Health Plans (HDHP) and Health
												Saving Accounts (HSA).
											</div>
										</div> -->
            </div>
            <div
              v-if="startFilingButtons"
              class="card-footer border-0 mt-0 pt-0"
            >
              <btn
                @click="$router.push({ name: 'Signup' })"
                block
                color="secondary"
                class="font-app"
              >
                START FILING
              </btn>
            </div>
          </div>
        </div>
        <!-- End Pricing -->
      </div>
      <div class="pricing-card-col">
        <div class="position-relative h-100">
          <div
            class="card clean_card h-100 pricing-card"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="card-header border-0 text-center">
              <span class="d-block h3 mb-0 pricing-card-header">State</span>
              <span class="d-block">
                <span class="font-size-4 text-primary font-weight-bold">
                  <span class="mr-n2"
                    ><template v-if="showOnlys"> Only</template> ${{ STATE_PRICE }}</span
                  >
                </span>
              </span>
              <span class="d-block">
                <span class="tooltip_">
                  Required if your U.S. income is from
                  <tooltip>
                    a state with state taxes
                    <template v-slot:content>
                      The states that are <u>tax-free</u> are Alaska, Florida,
                      Nevada, New Hampshire, South Dakota, Tennessee, Texas,
                      Washington and Wyoming.
                    </template>
                  </tooltip>
                </span>
              </span>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>Maximize Your State Tax Refund:</strong>
                  We review all available state deductions and automatically
                  apply the deductions that will maximize your tax refund.
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>Seamless Federal to State Experience: </strong
                  >Information from your federal filing is automatically applied
                  on your state taxes, saving you time and effort.
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>File with Confidence: </strong>State tax laws are
                  different from federal tax laws. We do all of the required
                  adjustments for you.
                </div>
              </div>
            </div>
            <div
              v-if="startFilingButtons"
              class="card-footer border-0 mt-0 pt-0"
            >
              <btn
                @click="$router.push({ name: 'Signup' })"
                color="secondary"
                block
                class="font-app"
              >
                START FILING
              </btn>
            </div>
            <!-- End Body -->
          </div>

          <!-- End SVG Elements -->

          <!-- End SVG Elements -->
        </div>
        <!-- End Pricing -->
      </div>

      <div class="pricing-card-col">
        <!-- Pricing -->
        <div class="position-relative h-100">
          <div
            class="card clean_card h-100 pricing-card"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <!-- Header -->
            <div class="card-header border-0 text-center">
              <span class="d-block h3 mb-0 pricing-card-header"
                >No U.S. Income – Form 8843</span
              >
              <span class="d-block">
                <span class="font-size-4 text-primary font-weight-bold">
                  <span class="mr-n2">
                    <template v-if="showOnlys"> Only</template>
                    ${{ FORM_8843_PRICE }}</span
                  >
                </span>
              </span>
              <span class="d-block">
                <span class="highlight">
                  Required if you are a F, J, M or Q visa holder to comply with
                  your visa terms, even if you didn’t have any U.S. income
                </span>
              </span>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>Optional Shipping Service: </strong>File your 8843
                  online and let us ship it for you.
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>Ensure Compliance with Your Visa Terms: </strong>File
                  online, save research time, and ensure accuracy and
                  compliance.
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fas fa-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body">
                  <strong>Dependent Family Members 8843: </strong>Spouse and
                  children 8843 forms also available
                </div>
              </div>
            </div>
            <div
              v-if="startFilingButtons"
              class="card-footer border-0 mt-0 pt-0"
            >
              <btn
                @click="$router.push({ name: 'Signup' })"
                color="secondary"
                block
                class="font-app"
              >
                START FILING
              </btn>
            </div>
            <!-- End Body -->
          </div>

          <!-- End SVG Elements -->
        </div>
        <!-- End Pricing -->
      </div>
    </div>
  </div>
</template>

<script>
import { FEDERAL_PRICE, STATE_PRICE, FORM_8843_PRICE } from "@/constants";

export default {
  data() {
    return {
      FEDERAL_PRICE,
      STATE_PRICE,
      FORM_8843_PRICE,
    }
  },
  props: {
    showOnlys: {
      type: Boolean,
      default: false
    },
    startFilingButtons: {
      type: Boolean,
      default: false
    }
  }
};
</script>
