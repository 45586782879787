<template>
	<p class="text-center m-0 p-0">
		International students, scholars, researchers, lecturers, teachers, au
		pairs, physicians and foreign professional employees – we’ve got you all
		covered!
	</p>
</template>

<script>
export default {

};
</script>
