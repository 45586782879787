<template>
	<youtube
		:fitParent="true"
		resize
		video-id="1xsjUaEBf3E"
		ref="youtube"
	></youtube>
</template>

<script>
export default {};
</script>
